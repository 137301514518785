<template>
  <v-dialog :width="totalSize" v-model="dialog">
    <template v-slot:activator="{ on: onDialog }">
      <v-tooltip bottom v-if="allLiq && !hidden && appointmentDates.length">
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn
            small
            icon
            v-on="{ ...onDialog, ...onTooltip }"
            class="mr-2"
            @click="open"
          >
            <v-icon small color="success">mdi-check</v-icon>
          </v-btn>
        </template>
        {{ $t("payed") }}
      </v-tooltip>
      <v-tooltip bottom v-else-if="!hidden && appointmentDates.length">
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn small icon v-on="{ ...onDialog, ...onTooltip }">
            <v-icon small>$eye</v-icon>
          </v-btn>
        </template>
        {{ $t("add_edit") }}
      </v-tooltip>
      <v-tooltip bottom v-else-if="!hidden">
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn small icon v-on="{ ...onDialog, ...onTooltip }" disabled>
            <v-icon small>$eye</v-icon>
          </v-btn>
        </template>
        {{ $t("add_edit") }}
      </v-tooltip>
    </template>
    <v-card id="cardListPrices">
      <v-card-title>
        {{ $t("appointments.price") }}
      </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <form action data-vv-scope="prices-form">
        <v-simple-table>
          <thead>
            <tr>
              <th style="min-width: 200px; max-width: 200px">Sesión</th>
              <th style="min-width: 130px; max-width: 130px">Precio</th>
              <th style="min-width: 130px; max-width: 130px">Pagado</th>
              <th style="min-width: 130px; max-width: 130px">Paga y señal</th>
              <th style="min-width: 130px; max-width: 130px">
                {{ $t("studio_com") }}
              </th>
              <th style="min-width: 130px; max-width: 130px">
                {{ $t("artist_com") }}
              </th>
              <th>Acciones</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(d, i) in appointmentDates" :key="i">
              <td style="min-width: 200px; max-width: 200px">
                {{
                  $browserDetect.isSafari ||
                  $browserDetect.meta.name === "Safari"
                    ? $d(new Date(d.date.substring(0, 10)), "date2digits") +
                      " " +
                      d.date.substring(11, 16)
                    : $d(new Date(d.date), "short")
                }}
              </td>

              <td style="min-width: 130px; max-width: 130px">
                <v-text-field
                  type="number"
                  v-on:keypress="isNumber($event)"
                  v-model="d.sesion_price"
                  data-vv-validate-on="blur"
                  v-validate="'required'"
                  :error-messages="errors.collect('prices-form.price-' + i)"
                  :data-vv-name="'price-' + i"
                  dense
                  hide-details
                  outlined
                  :disabled="!d.editable"
                  suffix="€"
                  @input="calculateComision(d, i)"
                ></v-text-field>
              </td>

              <td style="min-width: 130px; max-width: 130px">
                <v-text-field
                  :value="
                    i == 0
                      ? d.alreadyPayed +
                        totalTokenPaymentPayed +
                        appointment.deposit
                      : d.alreadyPayed
                  "
                  type="number"
                  disabled
                  dense
                  outlined
                  hide-details
                  suffix="€"
                ></v-text-field>
              </td>

              <td class="px-3" style="min-width: 130px; max-width: 130px">
                <v-text-field
                  v-if="i === 0"
                  type="number"
                  :value="totalTokenPayment"
                  disabled
                  dense
                  hide-details
                  outlined
                  suffix="€"
                >
                  <template v-slot:append-outer>
                    <v-tooltip bottom v-if="appointment.payed">
                      <template v-slot:activator="{ on }">
                        <v-btn small icon v-on="on" class="mr-1">
                          <v-icon small color="success">mdi-check</v-icon>
                        </v-btn>
                      </template>
                      {{ $t("payed") }}
                    </v-tooltip>
                  </template>
                </v-text-field>
                <span v-else>-</span>
              </td>

              <td style="min-width: 130px; max-width: 130px">
                <v-text-field
                  type="number"
                  :value="+d.sesion_price - +d.sesion_commision"
                  disabled
                  dense
                  hide-details
                  outlined
                  suffix="€"
                ></v-text-field>
              </td>

              <td style="min-width: 130px; max-width: 130px">
                <v-text-field
                  type="number"
                  v-on:keypress="isNumber($event)"
                  v-model="d.sesion_commision"
                  data-vv-validate-on="blur"
                  v-validate="'max_value:' + d.sesion_price"
                  :error-messages="errors.collect('prices-form.commision-' + i)"
                  :data-vv-name="'commision-' + i"
                  dense
                  hide-details
                  outlined
                  :disabled="!d.editable"
                  suffix="€"
                ></v-text-field>
              </td>

              <td style="min-width: 170px; max-width: 300px">
                <base-dialog
                  type="icon"
                  title="Resumen de pagos"
                  small
                  tooltip
                  icon="$eye"
                  :width="900"
                >
                  <v-simple-table slot="outside">
                    <thead>
                      <tr>
                        <th>Concepto</th>
                        <th>Cantidad</th>
                        <th>Metodo de pago</th>
                        <th>Cobrador</th>
                        <th>Fecha</th>
                        <th>Pagado</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-if="i == 0">
                        <tr
                          v-for="at in appointmentPaymentTokens.filter(
                            (at) => at.expense_id
                          )"
                          :key="at.id"
                        >
                          <td>{{ at.expense.conceptos }}</td>
                          <td>{{ $n(at.price, "currency") }}</td>
                          <td>{{ $t("expenses." + at.expense.pay_mode) }}</td>
                          <td>
                            {{
                              at.expense.user_id == appointment.studio_id
                                ? "Estudio"
                                : "Artista"
                            }}
                          </td>
                          <td>
                            {{ $d(new Date(at.expense.data), "dateShort") }}
                          </td>
                          <td>
                            <v-icon v-if="!at.expense" color="red"
                              >mdi-close</v-icon
                            >
                            <v-icon v-else color="success">mdi-check</v-icon>
                          </td>
                        </tr>
                      </template>
                      <tr v-for="e in orderExpenses(d)" :key="e.id">
                        <td>{{ e.conceptos }}</td>
                        <td>{{ $n(e.cantidad, "currency") }}</td>
                        <td>{{ $t("expenses." + e.pay_mode) }}</td>
                        <td>
                          {{
                            e.user_id == appointment.studio_id
                              ? "Estudio"
                              : "Artista"
                          }}
                        </td>
                        <td>{{ $d(new Date(e.data), "dateShort") }}</td>
                        <td>
                          <v-icon v-if="e.pending" color="red"
                            >mdi-close</v-icon
                          >
                          <v-icon v-else color="success">mdi-check</v-icon>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </base-dialog>
                <template v-if="!d.editable">
                  <v-btn @click="edit(d)" icon small class="d-inline-block">
                    <v-icon small>$edit</v-icon>
                  </v-btn>

                  <v-tooltip
                    bottom
                    v-if="
                      d.payed ||
                      (i == 0 &&
                        d.alreadyPayed +
                          totalTokenPaymentPayed +
                          appointment.deposit ==
                          d.sesion_price)
                    "
                  >
                    <!--MIRAR VARIABLE PAYED DE d-->
                    <template v-slot:activator="{ on }">
                      <v-btn small icon v-on="on" class="mr-2">
                        <v-icon small color="success">mdi-check</v-icon>
                      </v-btn>
                    </template>
                    {{ $t("payed") }}
                  </v-tooltip>

                  <PaymentTypes
                    v-else-if="$store.getters['auth/isLoggedIn']"
                    :appointment="appointment"
                    :appointmentDates="d"
                    :totalTokenPayment="i == 0 ? totalTokenPaymentPayed : 0"
                    @update="$emit('update')"
                    class="d-inline-block"
                  />
                </template>
              </td>
            </tr>

            <tr>
              <td class="primary--text" style="text-transform: uppercase">
                {{ $t("total") }}
              </td>
              <td>{{ $n(totalPrice, "currency") }}</td>
              <td>
                {{ $n(totalPayed, "currency") }}
              </td>
              <td>
                {{ $n(totalTokenPayment, "currency") }}
              </td>
              <td>
                {{ $n(totalStudio, "currency") }}
              </td>
              <td>
                {{ $n(totalTattooer, "currency") }}
              </td>

              <td>
                <v-tooltip bottom v-if="allLiq">
                  <template v-slot:activator="{ on }">
                    <v-btn small icon v-on="on" class="mr-2">
                      <v-icon small color="success">mdi-check</v-icon>
                    </v-btn>
                  </template>
                  {{ $t("payed") }}
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </form>

      <v-card-actions v-if="$store.getters['auth/isLoggedIn']">
        <v-spacer />
        <v-btn
          v-text="$t('cancel')"
          @click="
            dialog = false;
            $emit('update');
            $emit('close');
          "
          outlined
          style="height: 25px"
        />
        <v-btn
          @click="save"
          style="
            width: 100px !important;
            color: #363533;
            height: 25px;
            padding-top: 10px !important;
          "
          elevation="0"
        >
          <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
          {{ $t("save", { name: $t("") }) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "PriceList",
  props: {
    appointmentDates: {
      type: Array,
      default: () => [],
    },
    appointment: {},
    disabled: {},
    hidden: {},
    appointmentPaymentTokens: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      today: new Date(),
      dialog: false,
      dialogEdit: false,
      dialog_box: false,
      editSession: {},
      res: {},
      tattooers: [],
      boxes: [],
      timeStudio: [],
      timeVal: false,
    };
  },
  components: {
    AddSession: () => import("@/components/appointments/sessions/Add"),
    PaymentTypes: () =>
      import("@/components/appointments/sessions/PaymentTypes"),
    edit: () => import("./Edit"),
  },
  mounted() {
    this.today = new Date();
    //console.log("MONTED");
    //this.fetchBoxes();
    //this.fetchTattooers();
    //this.fetchTimeStudio();
  },
  computed: {
    totalSize() {
      let cols = 5;
      if (this.appointment.token_payment) cols++;
      if (this.appointmentPayed) cols++;
      return 7 * 150 + 70;
    },

    totalPrice() {
      let total = 0;
      this.appointmentDates.forEach((x) => (total += +x.sesion_price));
      console.log("TOTALPRICE", total);

      return total;
    },
    totalTattooer() {
      let total = 0;
      this.appointmentDates.forEach((x) => (total += +x.sesion_commision));
      console.log("TOTALTATOOER", total);
      return total;
    },

    appointmentPayed() {
      let payed = false;
      this.appointmentDates.forEach((x) => {
        if (x.alreadyPayed) {
          payed = true;
        }
      });
      console.log("payed", payed);
      return payed;
    },

    totalStudio() {
      return this.totalPrice - this.totalTattooer;
    },

    hasComisions() {
      //return false;
      let s = this.appointment.studio.user.setting.find(
        (x) => x.key === "comisions_active"
      );
      if (s) return s.value === "1";
      return true;
    },

    allLiq() {
      let t = true;
      this.appointmentDates.forEach((d, i) => {
        console.log("d", d, this.liq(d));
        if (!d.payed) {
          if (i == 0) {
            if (this.totalTokenPayment + d.alreadyPayed !== d.sesion_price) {
              t = false;
            } else if (d.sesion_price == 0) t = false;
          } else t = false;
          //comprovar si esta liquidado
        }
      });
      return t;
    },

    //total token payments for this session
    totalTokenPayment() {
      let totalAppointmentPaymentTokens = 0;
      if (this.appointmentPaymentTokens)
        this.appointmentPaymentTokens.forEach((d, i) => {
          totalAppointmentPaymentTokens += d.price;
          console.log(totalAppointmentPaymentTokens);
        });
      console.log("TOTAL AP", totalAppointmentPaymentTokens);
      return totalAppointmentPaymentTokens;
    },

    //total token payments for this session
    totalTokenPaymentPayed() {
      let totalAppointmentPaymentTokens = 0;
      if (this.appointmentPaymentTokens)
        this.appointmentPaymentTokens.forEach((d, i) => {
          if (d.payed) totalAppointmentPaymentTokens += d.price;
          console.log(totalAppointmentPaymentTokens);
        });
      console.log("TOTAL AP", totalAppointmentPaymentTokens);
      return totalAppointmentPaymentTokens;
    },

    totalPayed() {
      let total = 0;
      this.appointmentDates.forEach((x, i) => {
        console.log("ALREADYPAYED", x.alreadyPayed);
        if (i == 0) {
          total += x.alreadyPayed + this.totalTokenPaymentPayed;
        } else {
          total += x.alreadyPayed;
        }
      });

      console.log("TOTALPAYED", total);
      return total;
    },
    editable() {
      let e = false;
      this.appointmentDates.forEach((ad) => {
        if (ad.editable) e = true;
      });
      return e;
    },
  },
  methods: {
    orderExpenses(d) {
      return d.expenses
        .filter((e) => e.appointment_type == "main")
        .sort((e1, e2) => {
          if (e1.pending && !e2.pending) return 1;
          else if (!e1.pending && e2.pending) return -1;
          else return 0;
        });
    },
    calculateCommissions() {
      console.log("CALCULTE PRICES");
      this.appointmentDates.forEach((ad, i) => {
        this.calculateComision(ad, i);
      });
      this.update();
    },
    open() {
      this.appointmentDates = this.appointmentDates.map((a) => {
        return { ...a, editable: false };
      });
      this.appointmentDates.forEach((ad) => {
        if (ad.sesion_price == 0) this.edit(d);
      });
      this.dialog = false;
      this.dialog = true;
    },

    edit(d) {
      if (d.alreadyPayed) {
        this.$confirm(this.$t("session_already_payed")).then(() => {
          this.$set(d, "editable", true);
          d.editable = true;
          this.dialog = false;
          this.dialog = true;
        });
      } else {
        this.$set(d, "editable", true);
        d.editable = true;
        this.dialog = false;
        this.dialog = true;
      }
    },

    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9.]+$/.test(char)) return true;
      else e.preventDefault();
    },
    cantEdit(item, i) {
      return (
        item.payed ||
        (i == 0 &&
          this.totalTokenPayment + item.alreadyPayed === item.sesion_price &&
          item.sesion_price !== 0)
      );
    },
    liq(item) {
      let liq = false;
      console.log("liq", item, item.expenses);
      if (item.expenses.length) {
        item.expenses.forEach((ex) => {
          console.log(ex.pending);
          if (ex.pending == 0) {
            liq = true;
          }
        });
      }
      console.log(liq);
      return liq;
    },
    ...mapActions("expenses", ["confirmExpense"]),
    pay(item) {
      let c = item.expenses.find((e) => {
        if (this.appointment.studio.user.role_id == 9)
          return e.user_id === this.appointment.tattooer_id;
        return e.user_id === this.appointment.studio_id;
      });
      console.log(c);
      this.$confirm(this.$t("liquidate_session")).then(() => {
        this.confirmExpense(c.id).then(() => {
          this.$emit("update");
          this.$alert(this.$t("save_ok"), "", "");
        });
      });
    },
    ...mapActions("appointments", ["updatePrices"]),
    async save() {
      let result = await this.$validator.validateAll("prices-form");
      if (result) {
        this.update();
      }
    },
    update() {
      let info = this.appointmentDates.map((x) => {
        return {
          id: x.id,
          sesion_price: x.sesion_price,
          sesion_commision: x.sesion_commision,
        };
      });
      this.updatePrices({
        appointment_id: this.appointment.id,
        formData: { prices: info },
      }).then((data) => {
        this.$emit("update");
        this.$emit("close");
        this.dialog = false;
        this.$alert(this.$t("prices_updated"));
      });
    },
    calculateComision(line, i) {
      if (line.sesion_price && this.hasComisions) {
        console.log("CALCULAMOS");
        let c = this.appointment.tattooer.tattooer_studios[0].comisions;
        console.log(+c.price);
        console.log(line.sesion_price);
        if (c) {
          let comision = c.comisions.find((x) => {
            let from = x.from === "any" || x.from === this.appointment.from; //true
            let cantidad =
              x.price_pos === "any" ||
              (x.price_pos === "lower" && +line.sesion_price <= +c.price) ||
              (x.price_pos === "bigger" && +line.sesion_price > +c.price);
            return from && cantidad;
          });
          console.log(comision);
          if (comision.type === "money") {
            line.sesion_commision = comision.valor;
          } else {
            let p = line.sesion_price;
            line.sesion_commision = (p * +comision.valor) / 100;
          }
        }
      }
    },
  },
};
</script>
<style lang="sass">
#cardList
th
  font-size: 14px !important
</style>
